@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.details-session-list {
  $breakpoint: 800px;
  border-radius: corner-radius.$m;
  background-color: light.$background-secondary-default;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: spacing.$m;
  }

  &__show-more, &__show-prev, &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__show-more-button-wrapper {
    border-top: 1px solid light.$ge-divider-default;
    padding: spacing.$xs 0;
    margin-left: spacing.$s;

    @media(min-width: $breakpoint) {
      display: none;
    }
  }

  &__show-more-button {
    margin-left: spacing.$xxs;
  }

  &__show-more, &__show-prev {
    display: none;

    @media(min-width: $breakpoint) {
      display: block;
    }

    &--show {
      display: block;
    }
  }

  &__show-past-button-wrapper {
    padding: 0 spacing.$m spacing.$xs;

    @media(min-width: $breakpoint) {
      display: none;
    }
  }
}
